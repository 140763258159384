<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="600px"
      top="14%"
      :close-on-click-modal="false"
      @close="cloese"
      :title="moduleName"
    >
      <el-form
        :model="paramsDate"
        ref="fromDate"
        :rules="rules"
        label-position="right"
        style="margin: 0px auto"
      >
        <el-form-item label-width="150px" prop="productId" :label="$t('dc:产品名称')" required>
          <el-select
            v-model="paramsDate.productId"
            :disabled="disable"
            :clearable="true"
            :placeholder="$t('dc:请选择产品')"
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="150px"
          prop="firstModuleName"
          :label="$t('dc:模块名称_1st')"
          required
        >
          <el-input
            v-model="paramsDate.firstModuleName"
            maxlength="100"
            :placeholder="$t('dc:请输入')"
            show-word-limit
            class="show-word-limit55"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="150px" :label="$t('dc:模块名称_2st')">
          <el-input
            v-model="paramsDate.secondModuleName"
            maxlength="100"
            :placeholder="$t('dc:请输入')"
            show-word-limit
            class="show-word-limit55"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" style="text-align: right">
        <el-button @click="close" class="marginR20">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" @click="submitForm('fromDate')">{{ $t('dc:确认') }}</el-button>
      </span>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :className="classNameOne"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>

<script>
import config from '@/api/config'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'addModuleDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => [],
    },
    rowData: {},
  },
  data() {
    var productIdRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var firstModuleNameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (!new RegExp('^[A-Z].*|^\\*$').test(value)) {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var secondModuleNameRule = (rule, value, callback) => {
      if (!new RegExp('^[A-Z].*|^\\*$').test(value)) {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      show: this.visible,
      show4Dialog: false,
      disable: true,
      moduleName: '',
      classNameOne: 'testL',
      title: this.$t('dc:提醒'),
      content: this.$t('dc:您是否确认添加此模块?'),
      productList: [],
      paramsDate: this.rowData,
      rules: {
        productId: [{ required: true, message: this.$t('dc:请按规则输入'), trigger: 'change' }],
        firstModuleName: [{ validator: firstModuleNameRule, trigger: 'blur' }],
        secondModuleName: [{ validator: secondModuleNameRule, trigger: 'blur' }],
      },
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {},
  mounted() {
    this.initDate()
    this.initEvent()
  },
  methods: {
    initDate() {
      if (this.rowData.id) {
        this.disable = true
        this.moduleName = this.$t('dc:编辑模块')
        this.title = this.$t('dc:提醒')
        this.content = this.$t('dc:您是否确认编辑此模块?')
      } else {
        this.disable = false
        this.moduleName = this.$t('dc:新增模块')
        this.title = this.$t('dc:提醒')
        this.content = this.$t('dc:您是否确认添加此模块?')
      }
    },
    initEvent() {
      this.getProductList()
    },
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.show4Dialog = true
        } else {
          return false
        }
      })
    },
    close() {
      this.$emit('queryApi')
      this.show = false
    },
    async addModuleData(params) {
      const { status, msg } = await $Api.moduleList.addModule(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
        this.$emit('queryApi')
        this.show = false
      }
    },
    async editModuleData(params) {
      const { status, msg } = await $Api.moduleList.editModule(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
        this.$emit('queryApi')
        this.show = false
      }
    },

    confrom() {
      if (this.rowData.id) {
        this.editModuleData(this.paramsDate)
      } else this.addModuleData(this.paramsDate)
    },
    cloese() {
      this.$emit('queryApi')
    },
  },
}
</script>
<style lang="scss">
.marginB50 {
  margin-bottom: 50px !important;
}
</style>
